@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-Bold.eot");
  src: url("./assets/fonts/Gilroy-Bold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Gilroy-Bold.woff2") format("woff2"),
    url("./assets/fonts/Gilroy-Bold.woff") format("woff"),
    url("./assets/fonts/Gilroy-Bold.ttf") format("truetype"),
    url("./assets/fonts/Gilroy-Bold.svg#Gilroy-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-SemiBold.eot");
  src: url("./assets/fonts/Gilroy-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Gilroy-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/Gilroy-SemiBold.woff") format("woff"),
    url("./assets/fonts/Gilroy-SemiBold.ttf") format("truetype"),
    url("./assets/fonts/Gilroy-SemiBold.svg#Gilroy-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/fonts/SFProDisplay-Medium.eot");
  src: url("./assets/fonts/SFProDisplay-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/SFProDisplay-Medium.woff2") format("woff2"),
    url("./assets/fonts/SFProDisplay-Medium.woff") format("woff"),
    url("./assets/fonts/SFProDisplay-Medium.ttf") format("truetype"),
    url("./assets/fonts/SFProDisplay-Medium.svg#SFProDisplay-Medium")
      format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/fonts/SFProDisplay-Regular.eot");
  src: url("./assets/fonts/SFProDisplay-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/SFProDisplay-Regular.woff2") format("woff2"),
    url("./assets/fonts/SFProDisplay-Regular.woff") format("woff"),
    url("./assets/fonts/SFProDisplay-Regular.ttf") format("truetype"),
    url("./assets/fonts/SFProDisplay-Regular.svg#SFProDisplay-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
body {
  margin: 0;
  padding: 0;
  font-family: "SF Pro Display";
  font-weight: 400;
  background-color: #fcfdfd;
  color: #3e3e3e;
  overflow-x: hidden;
  position: relative;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Gilroy";
  font-weight: bold;
}
a {
  color: #162af1;
  text-decoration: none;
}
a:hover {
  color: #162af1;
  text-decoration: none;
}
/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}
*::-webkit-scrollbar-thumb {
  background-color: #162af1;
}
img.brand_logo {
  width: 110px;
}
.navbar-sticky--moved-up {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #f9f9fa;
  margin-top: 0 !important;
  -webkit-box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  border-radius: 0 !important;
  z-index: 999;
}
.navbar-sticky--on {
  margin-top: 0;
}
.navbar-sticky--transitioned {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
/* ul.navbar_left {
  margin-left: 26%;
} */
.bal{
  color: #3e3e3e;
  font-size: 16px;
  font-family: "Gilroy";
  font-weight: 600;
  line-height: normal;
  display: flex;
  flex-direction: column;
}
.tot{
  color: #5e5e5e;
  color: #6b6b6b;
  font-size: 16px;
  font-weight: 600;
}
.main_navbar a.nav-link {
  color: #3e3e3e;
  font-size: 16px;
  font-family: "Gilroy";
  font-weight: 600;
  line-height: normal;
}
.main_navbar a.nav-link.active,
.main_navbar a.nav-link:hover {
  color: #162af1;
  text-decoration: none;
}
.main_wrapper {
  min-height: 100vh;
}
.innerpages_wrapper {
  min-height: 100vh;
  margin: 50px 0;
  position: relative;
  background: url("../src/assets/images/banner_bg.png") no-repeat center;
  background-size: cover;
}
.main_navbar {
  margin-top: 15px;
  border-radius: 50px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(4.5px);
  padding: 12px 20px;
  z-index: 999;
}
.inner_title_wrapper {
  margin: 35px 0;
}
.inner_title {
  color: #333;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
}
.dash_wrapper {
  border-radius: 50px;
  border: 1px solid rgba(255, 255, 255, 0.50);
  background: rgba(255, 255, 255, 0.20);
  backdrop-filter: blur(4.5px);
  padding: 40px;
  margin-bottom: 30px;
}
.dash_box {
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(4.5px);
  border: 1px solid rgb(223 223 223 / 50%);
  padding: 30px;
  margin-bottom: 24px;
}
.dash_box_left_flex {
  display: flex;
  align-items: center;
}
.dash_box_left_flex > div {
  width: 100%;
}
.dash_box_left h3 {
  color: #212529;
  text-align: right;
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}
.dash_box_left h4 {
  color: #111;
  text-align: right;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}
.dash_box_right_flex {
  display: flex;
  align-items: center;
}
.dash_box_right_flex > div {
  width: 100%;
}
.dash_box_right h3 {
  color: #212529;
  text-align: left;
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}
.dash_box_right h4 {
  color: #111;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}
.tradingview-widget-container {
  height: 100%;
}
.tradingview-widget-container > div {
  height: 100%;
}
.time_card {
  min-width: 60px;
  width: 20%;
  padding: 8px 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.count_value {
  font-size: 32px;
  line-height: 38px;
}
.count_indicator {
  font-size: 12px;
  font-weight: 600;
}
.counter {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  background-blend-mode: overlay;
}
.px3 {
  padding-right: 3rem !important;
}
.affiliate_inpgrp {
  position: relative;
  margin: 14px 0;
}
.affiliate_inpgrp input {
  border-radius: 20px;
  background: #f5f5f7;
  border: 1px solid #f5f5f7;
  padding: 8px 12px;
  color: #212529;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
}
.affiliate_list li a {
  background: #b4b4b4;
  color: #fff;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: 0.2s all;
}
.affiliate_list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 15px;
  justify-content: space-between;
}
.affiliate_list li a:hover {
  background: #162af1;
}
.affiliate_list li a svg {
  fill: #fff;
}
.copy_icon {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
}
.txt_green {
  color: #162af1 !important;
}
.txt_red {
  color: #ff6363 !important;
}
.dash_box_right_top {
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 15px;
  margin-bottom: 12px;
}
.dash_box_right_top img {
  border-radius: 100px;
  width: 30px;
}
.dash_box_right_top h4 {
  font-size: 16px;
  font-weight: 600;
}
.flex-dashbox-top b span{
  font-size: 16px;
}
.flex-dashbox-top {
  justify-content: space-between;
}
.crypto-img-name {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}
.apy_tooltip {
  width: 250px !important;
}
.custom_tooltip {
  background: #fff !important;
  color: #111 !important;
  border: 1px solid #ebebeb;
  box-shadow: 0 4px 8px rgba(40, 40, 40, .08);
  opacity: 1 !important;
  z-index: 99;
}
.dash_box_right_bottom > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.dash_box_right_bottom .img_box{
  height: 170px;
  justify-content: center;
}
.dash_box_right_bottom .img_box img{
  max-height: 150px;
}
.dash_box_right_bottom > div label:nth-child(2) {
  font-weight: 500;
}
.dash_box_right_bottom h2 {
  color: #212529;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
}
.dash_box_right_bottom h2 span {
  color: #111;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
.primary_btn {
  padding: 6px 20px;
  font-size: 16px;
  text-transform: capitalize;
  color: #fff;
  border: 1px solid #162af1;
  border-radius: 20px;
  background: #162af1;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  font-family: "Gilroy";
  font-weight: 600;
}
.primary_btn:hover {
  border: 1px solid #162af1;
  background-color: transparent;
  box-shadow: 0px 15px 20px rgba(176, 214, 252, 0.80);
  color: #162af1;
}
.primary_btn_transform:hover {
  transform: translateY(-7px);
}
.button_grp {
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.custom_progress {
  position: relative;
}
.progress_value {
  position: absolute;
  top: -7px;
  right: 0;
  color: #162af1;
  font-size: 14px;
  font-weight: 700;
}
.custom_progress .progress {
  border-radius: 10px;
  background: #ececef;
  height: 10px;
}
.custom_progress .progress,
.progress_note {
  width: 95%;
}
.progress_note {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}
.progress_note label {
  color: #9d9d9d;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.progress_note label:nth-child(2) {
  color: #111;
  font-weight: 700;
}
.custom_progress .progress-bar {
  background: #162af1;
  border-radius: 10px;
}
.dash_box_right h5 {
  color: #111;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
.dash_box_right_large {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.dash_box_right_large_single {
  display: flex;
  align-items: center;
  gap: 30px;
  position: relative;
}
.dash_box_right_large_single h3 {
  color: #212529;
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
}
.dash_box_right_large_single::after {
  content: "";
  background: #e0e0e0;
  width: 1px;
  height: 63px;
  position: absolute;
  right: -42px;
  top: 0;
}
.dash_box_right_large_single:last-child::after {
  display: none;
}
.dash_box_right_large_single h5 {
  margin-bottom: 18px;
}
.dash_box.dash_box_right.dash_box_right_large {
  padding-top: 50px;
  padding-bottom: 50px;
}
.dash_box.dash_box_right {
  padding-top: 36px;
  padding-bottom: 36px;
}
.inner_subtitle_wrap h3 {
  color: #162af1;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
}
.inner_subtitle_wrap_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.primary_datatable {
  margin-top: 15px;
}
.primary_datatable .rdt_Table > div {
  background: rgb(22 42 241 / 10%);
}
.primary_datatable .rdt_TableHeadRow .rdt_TableCol {
  font-size: 16px;
  font-weight: 500;
  color: #212529;
}
.primary_datatable .rdt_Table {
  background: transparent;
}
.primary_datatable .rdt_TableBody .rdt_TableRow,
.primary_datatable .rdt_TableHeadRow {
  border-bottom: 0;
  background: transparent;
}
.primary_datatable .rdt_Pagination {
  border-top: 0;
  background: transparent;
}
.primary_datatable .rdt_TableBody .rdt_TableRow:nth-child(odd) {
  background: rgb(22 42 241 / 10%);
}
.primary_datatable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  text-transform: capitalize;
}
.primary_datatable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  font-size: 15px;
  font-weight: 400;
  color: #111;
}

.primary_datatable_chg .rdt_TableBody .rdt_TableRow .rdt_TableCell:nth-last-child(1) >div{
  overflow: visible;
  white-space: unset;
}
.footer {
  border: 1px solid rgba(255, 255, 255, 0.75);
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(4.5px);
  padding: 30px 0;
  position: relative;
  z-index: 1;
}
.footer_panel_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer_panel_bottom p {
  color: #3e3e3e;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.footer_panel_bottom p a {
  color: #1e1e1e;
  font-weight: 500;
}
.footer_panel_top a.navbar-brand img {
  width: 150px;
}
.footer_menu_links {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 30px;
  margin: 0;
}
.footer_menu_links li {
  position: relative;
}
.footer_menu_links li::after {
  content: "";
  background: #898989;
  width: 1px;
  height: 15px;
  position: absolute;
  top: 11px;
  right: -15px;
}
.footer_menu_links li:last-child:after {
  display: none;
}
.footer_menu_links li a {
  color: #3e3e3e;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
}
p.footer_abt_content {
  color: #0f0f0f;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px; 
}
.footer_panel_top h3 {
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.42px;
  text-transform: capitalize;
  margin-bottom: 15px;
}
.footer_quick_links {
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer_quick_links li a {
  color: #3e3e3e;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.42px;
  text-transform: capitalize;
}
.footer_social_links {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 0;
  margin: 0;
}
.footer_social_links li a {
  background: #111;
  color: #fff;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: 0.2s all;
}
.footer_social_links li a:hover {
  background: #162af1;
}
.footer_social_links li a svg {
  fill: #fff;
}
.footer_panel_top {
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.auth_btn_grp {
  display: flex;
  gap: 15px;
}
.offcanvas-header .btn-close {
  box-shadow: none;
}
.toolbar_bottom {
  display: none;
}
.page_header {
  position: relative;
  z-index: 1;
}
.page_header::before {
  content: "";
  background: url("../src/assets/images/banner_bg.png") no-repeat bottom right;
  background-size: cover;
  width: 100%;
  height: 901px;
  position: absolute;
  top: -150px;
  right: 0;
  z-index: -1;
}
body::after {
  content: "";
  background: url("../src/assets/images/footer_bg.png") no-repeat;
  background-size: cover;
  width: 100%;
  height: 449px;
  position: absolute;
  bottom: 0;
  z-index: -1;
}
.page_header_inner {
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.75);
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(4.5px);
  padding: 89px 57px 89px 56px;
  margin-top: 50px;
}
.primary_btn_with_bg {
  background: #162af1;
  color: #fcfdfd;
  box-shadow: 0px 8px 20px 0px #d3e7fb;
}
.primary_btn_with_bg:hover {
  background: #fcfdfd;
  color: #162af1;
}
.page_header h1 {
  color: #1e1e1e;
  font-size: 40px;
  font-weight: bold;
  line-height: 48px;
  margin-bottom: 30px;
}
.page_header h1 span {
  color: #162af1;
}
.page_header p {
  color: #3e3e3e;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
  z-index: 10;
  position: relative;
}
.main_wrapper {
  min-height: 100vh;
  z-index: 1;
  position: relative;
}
.section {
  padding: 50px 0;
}
.countdown_single {
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.75);
  background: rgba(255, 230, 201, 0.5);
  backdrop-filter: blur(4px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 30px;
  padding: 30px;
  width: 260px;
}
.countdown_single:nth-child(2) {
  background: rgba(231, 223, 255, 0.5);
}
.countdown_single:nth-child(3) {
  background: rgb(22 42 241 / 15%);
}
.countdown_panel {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}
.countdown_single h3 {
  color: #ff8a00;
  font-size: 36px;
  font-weight: bold;
  line-height: 48px;
  margin-bottom: 0;
}
.countdown_single p {
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 5px;
}
.countdown_single:nth-child(2) h3 {
  color: #8c64ff;
}
.countdown_single:nth-child(3) h3 {
  color: #162af1;
}
.countdown_section {
  padding: 0;
  margin-top: -80px;
  z-index: 10;
  position: relative;
}
.main_title {
  color: #1e1e1e;
  font-size: 40px;
  font-weight: bold;
  line-height: 48px;
  margin-bottom: 30px;
}
.main_title span {
  color: #162af1;
}
.main_para {
  color: #3e3e3e;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 30px;
}
.main_para b {
  color: #1e1e1e;
  font-weight: 500;
}
.features_panel {
  border-radius: 60px;
  background: #dafff2;
  padding: 50px 80px;
}
.features_panel h3 {
  color: #162af1;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  margin: 30px 0;
}
.features_panel p.main_para {
  max-width: 85%;
}
.staking_works {
  display: flex;
  align-items: flex-start;
  gap: 40px;
  margin-bottom: 30px;
}
.staking_works h2 {
  color: #1e1e1e;
  font-size: 20px;
  font-weight: bold;
  line-height: normal;
}
.staking_calc {
  border-radius: 40px;
  background: #fefefe;
  box-shadow: 0px 15px 25px 0px #e9e9e9;
  padding: 40px;
}
.faq_accordian {
  background: transparent;
}
.faq_accordian button {
  box-shadow: none !important;
}
.faq_accordian .accordion-item {
  border: none;
  border-bottom: 1px solid rgb(22 42 241 / 30%);
  border-radius: 0;
  background: transparent;
}
.faq_accordian .accordion-item:last-child {
  border-bottom: 0;
}
.faq_accordian .accordion-item button {
  background: transparent;
  color: #212529;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  padding: 15px 0;
}
.faq_accordian .accordion-button::after {
  background: url("../src/assets/images/faq_icon.png") no-repeat;
  width: 10px;
  height: 6px;
}
.faq_accordian .accordion-button:not(.collapsed)::after {
  transform: rotate(180deg);
}
.faq_accordian .accordion-body {
  padding: 10px 0 15px;
}
.faq_accordian .accordion-body .main_para {
  margin-bottom: 0;
  max-width: 90%;
}
.image_dropdown {
  width: 100%;
  text-align: left;
  border-radius: 20px;
  background: #f3f3f3 url("../src/assets/images/select_dropdown.png") no-repeat
    center right 15px !important;
  border: 1px solid #f3f3f3 !important;
  height: 60px;
  padding: 10px 15px;
  color: #1e1e1e !important;
  font-size: 16px;
}
.dropdown-toggle.image_dropdown::after {
  display: none;
}
.image_dropdown img,
.image_dropdown_wrapper .dropdown-menu img {
  margin-right: 10px;
}
.image_dropdown_wrapper .dropdown-menu {
  width: 100%;
  padding: 0;
  height: 330px;
  overflow-y: auto;
}
.image_dropdown_wrapper .dropdown-menu .dropdown-item {
  padding-top: 8px;
  padding-bottom: 8px;
}
.image_dropdown_wrapper .dropdown-menu .dropdown-item.active,
.image_dropdown_wrapper .dropdown-menu .dropdown-item:active,
.image_dropdown_wrapper .dropdown-menu .dropdown-item:focus,
.image_dropdown_wrapper .dropdown-menu .dropdown-item:hover {
  background: rgb(0 184 129 / 80%);
  color: #fff;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.staking_calc input {
  height: 60px;
  border-radius: 20px;
  background: #f3f3f3 !important;
  border: 1px solid #f3f3f3 !important;
  padding: 10px 15px;
  color: #1e1e1e !important;
  font-size: 16px;
  box-shadow: none !important;
}
.staking_calc .input-group .primary_asset_inp {
  height: 60px;
  font-size: 30px;
  border-radius: 20px;
  border: 0 !important;
  background: #b0d6fd !important;
}
.MuiSlider-root {
  color: #162af1 !important;
}
.staking_calc label {
  color: #3e3e3e;
  font-size: 16px;
  margin-bottom: 10px;
}
.staking_calc [class*="col-"] {
  margin-bottom: 30px;
}
.inactive {
  display: none;
}
.staking_method_btngrp {
  display: flex;
  gap: 10px;
}
.staking_method_btngrp button {
  color: #162af1;
  font-family: "Gilroy";
  font-weight: 600;
  border-radius: 20px;
  border: 1px solid #162af1;
  background: transparent;
  /* padding: 12px 15px; */
  white-space: nowrap;
  width: 100%;
  height: 60px;
}
.staking_method_btngrp button.active {
  background: #162af1;
  color: #fff;
}
.staking_result_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 80px;
  border-radius: 20px;
  border: 1px solid #e5e3e3;
  background: rgba(255, 255, 255, 0.15);
}
.staking_result_div p {
  color: #3e3e3e;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
}
.staking_result_div h3 {
  color: #1e1e1e;
  font-size: 40px;
  line-height: normal;
  font-weight: bold;
}
.staking_result_div span {
  color: #b7b7b7;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}
.auth_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 120px 0;
  position: relative;
}
.auth_wrapper .dash_box {
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(4.5px);
  position: relative;
  padding: 50px 80px;
  z-index: 1;
}
.innerpages_wrapper::before {
  content: "";
  background: url("../src/assets/images/banner_bg.png") no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: -120px;
  z-index: -1;
  opacity: 0.8;
}
.auth_form input.form-control {
  background: rgb(22 42 241 / 5%);
  border: 1px solid rgba(24, 42, 242, 0.30);
  backdrop-filter: blur(2.5px);
  border-radius: 50px;
  height: 56px;
  padding-left: 25px;
  box-shadow: none !important;
  color: #111;
}
.auth_form input.form-control::placeholder {
  color: #111;
}
.auth_form .form-check-input:checked {
  background-color: #162af1;
  border-color: #162af1;
  box-shadow: none !important;
}
.auth_form .form-check-input:focus {
  box-shadow: none !important;
}
.auth_form .primary_btn_with_bg {
  padding: 12px 18px;
  font-size: 18px;
}
ul.nav_after_login {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
ul.nav_after_login.box {
  background: #d8deff;
  padding: 10px;
  border-radius: 10px;

}

.nav_after_login li {
  position: relative;
}
.nav_after_login li a.nav-link {
  color: #1e1e1e !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.nav_after_login .dropdown svg {
  margin-right: 10px;
}
.notify_active {
  background: #162af1;
  border: 2px solid #fff;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  position: absolute;
  right: -6px;
  top: -4px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.nav_after_login li a.dropdown-item {
  color: #1e1e1e;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.nav_after_login li a.dropdown-item:focus,
.nav_after_login li a.dropdown-item:hover {
  color: #fff;
  background-color: #162af1;
}
.level_top_row .dash_box_right {
  padding-top: 20px !important;
  padding-bottom: 0 !important;
  height: 100%;
}
.level_top_row .dash_box_left {
  height: 100%;
  padding: 10px 30px;
}
.level_top_row [class*="col-"] {
  margin-bottom: 24px;
}
.level_head,
.level_body_row {
  display: flex;
}
.level_head > div:nth-child(1),
.level_body_row > div:nth-child(1) {
  width: 25%;
  margin-right: 80px;
}
.level_head > div:nth-child(2),
.level_body_row > div:nth-child(2) {
  width: 40%;
}
.level_head > div:nth-child(3),
.level_body_row > div:nth-child(3) {
  width: 20%;
}
.level_head > div {
  color: #162af1;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.level_body_row > div {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
}
.level_body_row {
  background: #f0f0f0;
  height: 56px;
  margin-bottom: 40px;
  align-items: center;
  border-radius: 30px;
}
.level_ribbon {
  position: relative;
  background: url("../src/assets/images/level_default.png") left center
    no-repeat;
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #162af1;
}
.level_ribbon.active {
  background: url("../src/assets/images/level_active.png") left center no-repeat;
  color: #fff;
}
.level_table {
  margin-top: 50px;
}
.level_head {
  padding-bottom: 30px;
}
.asset_dashbox_flex {
  display: flex;
  align-items: flex-start;
  gap: 60px;
}
.asset_dashbox_flex_line {
  position: relative;
}
.asset_dashbox_flex_left,
.asset_dashbox_flex_right {
  width: 50%;
}
.asset_dashbox_flex_line::after {
  content: "";
  width: 1px;
  height: calc(100% - 60px);
  background: #e0e0e0;
  position: absolute;
  right: 50%;
}
.asset_deposit_innertitle {
  color: #162af1;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}
.primary_warning_alert {
  border-radius: 29px;
  background: #f5f5f7;
  border-color: #f5f5f7;
  display: flex;
  align-items: flex-start;
  gap: 15px;
}
.primary_warning_alert svg {
  min-width: 26px;
  height: 26px;
}
.primary_warning_alert h3 {
  color: #111;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.primary_warning_alert p {
  color: #111;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.deposit_notes_list {
  color: #111;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
  padding-left: 15px;
}
.deposit_notes_list li {
  margin-bottom: 15px;
}
.asset_deposit_grp {
  position: relative;
}
.asset_deposit_grp input {
  padding-right: 40px;
}
.primary_asset_inp {
  height: 45px;
  border-radius: 30px;
  background: rgb(22 42 241 / 5%) !important;
  border-color: rgba(24, 42, 242, 0.30) !important;
  padding: 10px 20px;
  box-shadow: none !important;
  color: #111;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
}
span.primary_asset_inp {
  border: 1px solid rgba(24, 42, 242, 0.30) !important;
  border-left: none !important;
}
.asset_copy_icon {
  position: absolute;
  top: 15px;
  right: 18px;
  cursor: pointer;
}
.deposit_asset_details > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.deposit_asset_details > div label {
  color: #111;
  font-size: 16px;
  font-weight: 400;
  line-height: 36px;
  text-transform: capitalize;
}
.deposit_asset_details > div label:nth-child(2) {
  color: #212529;
  font-weight: 500;
}
.inner_title_wrapper_flex {
  display: flex;
  align-items: center;
  gap: 10px;
}
.assets_form label {
  color: #111;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  display: block;
}
.withdrawal_type {
  color: #212529;
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 14px;
  right: 18px;
}
.deposit_asset_details > div.font_bold {
  margin-top: 30px;
}
.deposit_asset_details > div.font_bold label {
  color: #0b0e11;
  font-weight: 600;
}
label.max_label {
  color: #162af1;
  font-size: 16px;
  cursor: pointer;
  font-weight: 700;
  margin-bottom: 8px;
}
.transfer_modal .modal-dialog {
  max-width: 700px;
}
.primary_modal .modal-header h1 {
  color: #1E1E1E;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
}
.primary_modal .modal-header .btn-close {
  box-shadow: none !important;
}
.asset_modal_btn_grp {
  display: flex;
  gap: 24px;
}
.primary_asset_select {
  height: 45px;
  border-radius: 30px;
  border-color: rgba(24, 42, 242, 0.30) !important;
  background: rgb(22 42 241 / 5%) url("../src/assets/images/select_dropdown.png") no-repeat;
  background-position: right 0.75rem center;
  background-size: 14px 9px !important;
  padding: 10px 20px;
  box-shadow: none !important;
  color: #111;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
}
.asset_transfer_flex {
  display: flex;
  align-items: center;
  gap: 20px;
}
.trade_user_detail {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}
.trade_user_detail h3 {
  color: #212529;
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: -0.96px;
  margin-bottom: 0;
}
.trade_user_detail img {
  box-shadow: 0 15px 25px 0 #e9e9e9;
  border-radius: 100px;
  width: 72px;
  height: 72px;
}
.copy_trade_row .dash_box {
  height: 100%;
}
.copy_trade_row [class*="col-"] {
  margin-bottom: 24px;
}
.cusform_form_switch .form-check-input {
  box-shadow: none !important;
  border-color: #d7d7d7;
}
.cusform_form_switch .form-check-input:checked {
  background-color: #162af1;
  border-color: #162af1;
}
.primary_tab {
  display: flex;
  gap: 30px;
  padding: 0 0 30px;
  border-bottom: 1px solid #efefef;
}
.primary_tab li button.nav-link {
  border-radius: 50px;
  border: 1px solid #d9d9d9;
  color: #afafaf;
  background: transparent;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: 500;
}
.primary_tab li button.nav-link.active {
  border: 1px solid #d9d9d9;
  background: #d9d9d9;
  color: #0b0e11;
}
.notification_panel_row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #efefef;
  padding: 30px 0;
  cursor: pointer;
}
.notification_panel_row:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
.unread_dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin-top: 6px;
  background: #fd2626;
  position: absolute;
}
.read_dot {
  background: #fff;
}
.notification_row_content {
  margin-left: 15px;
}
.notification_row_content h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}
.notification_row_content p {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0;
}
.notification_panel_row p.notify_date {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  color: var(--grey-07);
}
.notification_panel_row_empty > div {
  width: 100%;
  align-items: center;
}
.notification_panel_row > div {
  display: flex;
  align-items: flex-start;
  width: 65%;
}
.no_orders {
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center !important;
  flex-direction: column;
  width: 100% !important;
}
.user_info {
  display: flex;
  align-items: center;
  gap: 30px;
}
.user_info img {
  box-shadow: 0 15px 25px 0 #e9e9e9;
  border-radius: 100px;
  width: 72px;
  height: 72px;
}
.user_info_content h3 {
  color: #0b0e11;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 5px;
}
.user_info_content h5 {
  display: flex;
  align-items: center;
  gap: 30px;
}
.user_info_content h5 span {
  color: #111;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
}
.user_info_content h6 {
  color: #999999;
  font-size: 13px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
}
.user_info_content h6 span {
  cursor: pointer;
}
.center_line {
  margin: 0 5px;
  font-size: 10px;
}
.custom_file_upload {
  position: relative;
  overflow: hidden;
  background: #162af1 !important;
  padding: 8px 30px;
  color: #fff !important;
}
.custom_file_upload input[type="file"] {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}
.alertCard h3 {
  font-weight: 700;
}
.refer-icon {
  height: 16px;
}
.custom_nav_tabs {
  gap: 10px;
}
.custom_nav_tabs button.nav-link {
  color: #162af1;
  font-family: "Gilroy";
  font-weight: 600;
  border-radius: 20px;
  border: 1px solid #162af1;
  background: transparent;
  padding: 12px 20px;
  white-space: nowrap;
  min-width: 150px;
}
.custom_nav_tabs button.nav-link.active {
  background: #162af1;
  color: #fff;
}
.staking_top_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.search_grp {
  position: relative;
}
.search_grp input {
  padding: 12px 20px;
  box-shadow: none !important;
  border-radius: 20px;
  background: rgb(22 42 241 / 5%) !important;
  border-color: rgb(22 42 241 / 30%) !important;
  width: 300px;
}
.search_icon {
  position: absolute;
  top: 16px;
  right: 20px;
  width: 18px;
}
.input-group .primary_asset_select {
  flex: 0 1 auto;
  width: 140px;
}
.alert-success {
  color: #1E1E1E;
  background: rgba(0, 184, 129, 0.3);
}
.wallet_table {
  background: transparent;
}
.wallet_table th {
  font-size: 16px;
  font-weight: 500;
  color: #212529;
  background: transparent;
  border: none;
  padding: 10px 30px;
}
.wallet_table td {
  font-size: 15px;
  font-weight: 400;
  color: #111;
  vertical-align: middle;
  border: none;
  white-space: nowrap;
  background: transparent;
  padding: 10px 30px;
}
.table_asset {
  display: flex;
  align-items: center;
  gap: 10px;
}
.table_asset img {
  width: 36px;
}
.table_asset span {
  font-size: 15px;
  font-weight: 400;
  color: #111;
}
.wallet_table td:last-child {
  width: 300px;
}
.wallet_table tbody tr:nth-child(odd) {
  background: rgb(22 42 241 / 10%);
}
.wallet_bal {
  display: flex;
  align-items: center;
  gap: 10px;
}
.wallet_bal h4 {
  margin-bottom: 0;
  font-size: 18px;
}
.wallet_page_right_flex {
  display: flex;
  align-items: center;
  gap: 15px;
}
.wallet_page_right_flex .form-check-input {
  box-shadow: none !important;
}
.wallet_page_right_flex .form-check-input:checked {
  background-color: #162af1 !important;
  border-color: #162af1 !important;  
}
.dash_wrapper.dash_wrapper_flex {
  display: flex;
  align-items: flex-start;
  gap: 50px;
  position: relative;
}
.dash_wrapper_flex_left, 
.dash_wrapper_flex_right {
  width: 50%;  
}
.dash_wrapper_flex::after {
  content: "";
  width: 1px;
  height: calc(100% - 60px);
  background: rgba(0, 184, 129, 0.30);
  position: absolute;
  right: 50%;
}
textarea.form-control.primary_asset_inp {
  height: auto;
  resize: none;
}
.uploaded_files_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #d3e7fb;
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: 10px;
}
.uploaded_files_row > div span {
  font-size: 12px;
  width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 5px;
}
.dash_wrapper_flex_left .primary_btn {
  padding: 15px 20px;
}
.dash_wrapper_flex_right .search_grp input {
  width: 100%;
}
.dash_wrapper_flex .inner_subtitle_wrap_flex {
  margin-bottom: 15px;
}
.support_ticket_accordian .accordion-header .accordion-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: none !important;
  background: transparent !important;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}
.support_ticket_accordian .accordion-header .accordion-button > div {
  display: flex;
  gap: 20px;
  margin-right: 30px;
}
.support_ticket_accordian .accordion-button::after {
  position: absolute;
  margin-left: unset;
  right: 0;
}
.ticket_msg_card_user {
  width: 85%;
}
.ticket_msg_card {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(160, 160, 160, 0.35);
  padding: 30px;
  margin-bottom: 24px;
}
.ticket_msg_card_header {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 20px;
}
.ticket_msg_card_admin {
  width: 85%;
  margin-left: auto;
}
.ticket_msg_card_header h4 {
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
}
.ticket_msg_card_header p {
  color: #5e5e5e;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}
.ticket_msg_card_desc p {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 0;
}
.ticket_msg_card i {
  font-size: 24px;
}
.chat_reply {
  position: relative;
}
.chat_reply i {
  position: absolute;
  top: 8px;
  right: 20px;
  cursor: pointer;
  color: #162af1;
  font-size: 20px;
}
.faq_accordian .accordion-item button.primary_btn {
  width: auto;
  padding: 10px 30px;
}
.faq_accordian .accordion-item .asset_modal_btn_grp {
  justify-content: flex-end;
}
.ticket_msg_card_wrapper {
  height: 500px;
  overflow: auto;
  padding-right: 10px;
}
.stake_modal .staking_method_btngrp button {
  height: auto;
  padding: 5px 12px;
}
.deposit_asset_details > div.modal_staking_period {
  flex-direction: column;
  align-items: flex-start;
}

.phone_num .form-control {
  width: 100% !important;
  padding-left: 40px !important;
  background-color: transparent !important;
  border: 0;
}
.phone_num .react-tel-input .selected-flag:hover, 
.phone_num .react-tel-input .selected-flag:focus{
  background-color: transparent;
}
.phone_num .react-tel-input .flag-dropdown{
  background-color: transparent;
  border: 0;
}
.phone_num .react-tel-input .flag-dropdown.open{
  background: transparent;
}
.phone_num .react-tel-input .flag-dropdown.open .selected-flag{
  background: transparent;
}
.ticker_panel {
  border-radius: 20px;
  border: 1px solid #162af1;
  background: rgba(23, 41, 242, 0.1);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 20px;
  margin: 0 15px;
}
.ticker_panel img {
  width: 56px;
  height: 56px;
}
.ticker_content h3 {
  font-size: 24px;
  margin-bottom: 0;
  line-height: 30px;
}
.ticker_content h4 {
  margin-bottom: 0;
  font-family: "SF Pro Display";
  font-size: 18px;
  font-weight: 500;
  display: flex;
  gap: 20px;
}
.ticker_settings .slick-prev:before, 
.ticker_settings .slick-next:before {
  color: #162af1 !important;
}
.auth_form span.withdrawal_type {
  top: 18px;
  font-size: 20px;
  color: #162af1;
}
.staking_calc.trade_buy {
  padding: 0;
  border-radius: 0;
  box-shadow: none;
  background: transparent;
}
.staking_calc.trade_buy .image_dropdown_wrapper .dropdown-menu {
  height: 200px;
}
.staking_calc.trade_buy .form-group {
  margin-top: 0;
  margin-bottom: 15px;
}
.staking_calc.trade_buy label {
  margin-bottom: 3px;
}
.rc-slider-dot {
  bottom: -5px !important;
  width: 14px !important;
  height: 14px !important;
}
.rc-slider {
  padding-left: 30px;
  padding-right: 30px;
}
.rcslider_custom {
  padding: 0 10px;
}
.staking_method_btngrp button.trade_btn {
  height: auto;
  padding: 8px;
  border: 2px solid #fff;
  background: #fff;
}
button.trade_buy.trade_btn {
  color: #162af1;
}
button.trade_sell.trade_btn {
  color: #d53d3d;
}
button.trade_buy.trade_btn.active {
  background: #162af1;
  border: 1px solid #162af1;
  color: #fff;
}
button.trade_sell.trade_btn.active {
  background: #d53d3d;
  border: 1px solid #d53d3d;
  color: #fff;
}
.staking_calc.trade_buy .staking_method_btngrp {
  margin-bottom: 15px;
}
.staking_calc.trade_buy button.primary_btn {
  margin-top: 30px;
  padding: 10px;
  font-size: 18px;
}
button.primary_btn.primary_sell_btn {
  border: 1px solid #d53d3d;
  background: #d53d3d;
}
.dash_box.trade_top_info {
  padding: 10px 30px;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
}
.trade_top_info > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.trade_top_info > div label {
  font-weight: 500;
}
.trade_top_info > div label:nth-child(2) {
  color: #162af1;
}
.gap-10 {
  gap: 0 10px;
}


.crypto_box{
  background-color: #d2daf0;
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid #162af1;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0 5px;
}
.crypto_box p{
  margin-bottom: 0;
  font-weight: 500;
  color: #000000;
}
.crypto_box span{
  font-size: 15px;
}
.copy_trade_row .slick-prev:before,.copy_trade_row .slick-next:before{
  background-image: url("./assets/images/nav_arrow.png");
  background-repeat: no-repeat;
  width: 10px;
  height: 17px;
  font-size: 0;
  display: block;
}
.copy_trade_row .slick-next:before{
  transform: rotate(180deg);
}
.coins_box >div{
  margin:5px 0;
  background-color: #fff;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
}
.coins_box >div p{
  margin-bottom: 0;
  text-transform: uppercase;
}
.coins_box .accordion-item{
  margin-bottom: 12px !important;
  border-radius: 10px !important;
  background: #d2daf0;
  border: 1px solid #162af1 !important;
}
.coins_box .accordion-item:last-child{
  margin-bottom: 0 !important;
}
.coins_box .accordion-item .accordion-button{
  border-radius: 10px !important;
  background: #d2daf0;
  color: #14191a;
  box-shadow: none !important;
}
.coins_box .accordion-item .accordion-button::after{
  filter: invert(35%) sepia(66%) saturate(6849%) hue-rotate(232deg) brightness(103%) contrast(90%) !important;
}
.accordion-button::after {
  filter: contrast(0) brightness(0) invert(1) !important;
}

/* Media Queries */
@media screen and (max-width: 1399px) {
  .dash_wrapper {
    border-radius: 30px;
    padding: 20px;
  }
  .dash_box.dash_box_right {
    padding: 36px 20px;
  }
  .dash_box_right_large_single h3 {
    font-size: 18px;
  }
  .primary_btn.primary_btn_transform {
    font-size: 14px;
    padding: 8px 12px;
  }
  .dash_box_right_top h4 {
    font-size: 16px;
  }
  .dash_box_right_bottom h2 {
    font-size: 28px;
  }
  .countdown_section {
    margin-top: -120px;
  }
  .level_head > div:nth-child(1),
  .level_body_row > div:nth-child(1) {
    width: 32%;
  }
}
@media screen and (max-width: 1199px) {
  .dash_box.dash_box_left {
    height: 100%;
  }
  .dashbox_left_inner_row [class*="col-"] {
    margin-bottom: 24px;
  }
  .page_header::after {
    display: none;
  }
  .countdown_section {
    margin-top: 0;
    padding-top: 70px;
  }
  .countdown_panel {
    flex-wrap: wrap;
    justify-content: center;
  }
  .features_panel p.main_para {
    max-width: 100%;
  }
  ul.navbar_left {
    margin-left: 20px;
    display: none;
  }
  .main_navbar {
    backdrop-filter: unset;
    background: rgb(255 255 255 / 80%);
  }
  .navbar-toggler {
    filter: invert(1);
    box-shadow: none !important;
  }
  .notify_active {
    right: -28px;
    top: 6px;
    left: unset;
  }
  .bal{
    color: #1e1e1e !important;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
  }
  .support_ticket_accordian .accordion-header .accordion-button {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .support_ticket_accordian .accordion-button::after {
    top: 30px;
  }
  .tradingview-widget-container {
    height: 500px;
  }
}
@media screen and (max-width: 991px) {
  p.footer_abt_content {
    max-width: 90%;
  }
  .dash_box_right_large_single {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  .dash_box_right_large_single h5 {
    margin-bottom: 10px;
  }
  .dash_box_right_large_single::after {
    height: 100%;
  }
  .navbar_left {
    display: none;
  }
  .auth_btn_grp {
    flex-direction: column;
  }
  .auth_btn_grp .primary_btn {
    display: block;
    text-align: center;
  }
  .page_header_inner {
    text-align: center;
  }
  .footer {
    padding: 30px 0 80px;
  }
  .toolbar_bottom {
    display: block;
    position: fixed;
    bottom: 0;
    background: #162af1;
    width: 100%;
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.12);
    transition: transform 0.25s;
    z-index: 999;
  }
  .toolbar_bottom ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 15px;
    height: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .toolbar_bottom ul li svg {
    fill: #fff;
  }
  .toolbar_bottom ul li {
    text-align: center;
  }
  .toolbar_bottom ul li span {
    display: block;
    color: #fff;
    font-size: 14px;
  }
  .toolbar_bottom .navbar-toggler {
    filter: unset;
  }
  .toolbar_bottom ul li a,
  .toolbar_bottom ul li button {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    padding: 12px;
  }
  .toolbar_bottom ul li a.active {
    background: #075e3f;
  }
  .level_head > div:nth-child(1),
  .level_body_row > div:nth-child(1) {
    width: 40%;
    margin-right: 30px;
  }
  .asset_dashbox_flex {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    gap: 30px;
  }
  .asset_dashbox_flex_left,
  .asset_dashbox_flex_right {
    width: 100%;
  }
  .asset_dashbox_flex_line::after {
    display: none;
  }
  .staking_calc [class*="col-"] {
    margin-bottom: 15px;
  }
  .auth_wrapper .dash_box {
    padding: 30px;
  }
  .dash_wrapper.dash_wrapper_flex {
    flex-direction: column;
  }
  .dash_wrapper_flex_left, 
  .dash_wrapper_flex_right {
    width: 100%;
  }
  .dash_wrapper_flex::after {
    display: none;
  }
  .staking_top_flex {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }  
  .dash_box.trade_top_info {
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 50px;
    row-gap: 5px;
  }
}
@media screen and (max-width: 767px) {
  .dash_box_right_large {
    flex-direction: column;
    align-items: flex-start;
  }
  .dash_box_right_large_single {
    width: 100%;
    flex-direction: row;
  }
  .dash_box_right_large_single::after {
    width: 100%;
    height: 1px;
    right: unset;
    top: unset;
    bottom: -15px;
  }
  .progress_note {
    flex-direction: column;
    gap: 10px;
  }
  .custom_progress .progress,
  .progress_note {
    width: 92%;
  }
  .progress_note label {
    text-align: center;
  }
  .footer_panel_bottom {
    flex-direction: column;
    text-align: center;
  }
  .footer_panel_bottom p {
    line-height: 24px;
  }
  .page_header h1 {
    font-size: 36px;
    line-height: 46px;
  }
  .countdown_single h3 {
    font-size: 32px;
    line-height: 42px;
  }
  .features_panel {
    padding: 30px 50px;
  }
  .main_title {
    font-size: 36px;
    line-height: 42px;
  }
  .features_panel h3 {
    margin: 10px 0;
  }
  .auth_wrapper {
    padding: 50px 0;
  }
  .faq_accordian .accordion-item button {
    font-size: 16px;
  }
  .level_head > div:nth-child(1),
  .level_body_row > div:nth-child(1) {
    width: 180px;
    margin-right: 10px;
  }
  .level_head > div:nth-child(3),
  .level_body_row > div:nth-child(3),
  .level_head > div:nth-child(2),
  .level_body_row > div:nth-child(2) {
    width: 150px;
    margin-right: 10px;
  }
  .deposit_asset_details > div {
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .deposit_asset_details > div label {
    width: 100%;
    line-height: 24px;
  }
  .notification_panel_row {
    flex-direction: column;
  }
  .notification_panel_row p.notify_date {
    margin-left: 15px;
  }
  .level_ribbon,
  .level_ribbon.active {
    background-size: contain;
  }
  .notify_active {
    right: -25px;
    left: unset;
  }
  .staking_method_btngrp {
    flex-wrap: wrap;
  }
  .staking_method_btngrp button {
    width: 48%;
  }
  .staking_result_div p {
    text-align: center;
  }
  .staking_result_div {
    flex-direction: column;
    padding: 30px;
    text-align: center;
  }
  .page_header_inner {
    padding: 30px;
  }
  .support_ticket_accordian .accordion-header .accordion-button > div {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .ticket_msg_card_user,
  .ticket_msg_card_admin {
    width: 100%;
  }
  .ticket_msg_card {
    padding: 15px;
  }
  .search_grp,
  .search_grp input {
    width: 100%;
  }
  .wallet_page_right_flex {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media screen and (max-width: 575px) {
  .inner_subtitle_wrap_flex {
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
  }
  .dash_box_right_large_single h3 {
    font-size: 16px;
  }
  .dash_box_right h5 {
    font-size: 14px;
  }
  .level_body_row > div,
  .level_head > div {
    font-size: 14px;
  }
  .custom_progress .progress,
  .progress_note {
    width: 85%;
  }
  .asset_dashbox_flex {
    padding: 15px;
  }
  .assets_form label,
  label.max_label {
    font-size: 14px;
  }
  .deposit_asset_details > div label {
    font-size: 15px;
  }
  .asset_transfer_flex {
    flex-wrap: wrap;
    justify-content: center;
  }
  .asset_transfer_flex img {
    transform: rotate(90deg);
  }
  .user_info {
    flex-direction: column;
    align-items: flex-start;
  }
  .staking_method_btngrp button {
    width: 100%;
    padding: 12px 15px;
  }
  .page_header h1 {
    font-size: 26px;
    line-height: 32px;
  }
  .auth_wrapper .dash_box {
    padding: 30px 15px;
  }
  .ticker_panel {
    width: 85%;
    margin: 0 auto;
    gap: 5px;
  }
  .ticker_settings .slick-prev {
    left: 0;
    z-index: 1;
  }
  .ticker_settings .slick-next {
    right: 0;
  }
  .ticker_panel img {
    width: 40px;
    height: 40px;
  }
  .ticker_content h3 {
    font-size: 20px;
  }
  .ticker_content h4 {
    font-size: 16px;
    gap: 10px;
  }
}
.blog_content {
  height: 150px;
  display: block !important;
  margin-bottom: 0 !important;
  overflow-x: auto;
  padding-right: 8px;
}
.verticalScroll > div:first-child{
  padding-right: 20px !important;
}
.verticalScroll > div:last-child{
  margin-right: 0px;
  opacity: 1 !important;
  width: 5px !important;
}

.custom_pagination {
  justify-content: center;
  margin-top: 30px;
}
.blog_title {
  font-size: 20px !important;
  font-weight: 700 !important;
}
.custom_pagination .active>.page-link, 
.custom_pagination .page-link.active {
  background-color: #162af1;
  border-color: #162af1;
  color: #181A20;
}
.custom_pagination .page-link {
  color: #c7c7c7;
  box-shadow: none !important;
  border: 1px solid #282f36;
  background: #1e2329;
}



.border_btn {
  padding: 6px 20px;
  font-size: 16px;
  text-transform: capitalize;
  color: #162af1;
  border: 1px solid #162af1;
  border-radius: 20px;
  background: transparent;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  font-family: "Gilroy";
  font-weight: 600;
}
.border_btn:hover {
  color: #fff;
  border: 1px solid #162af1;
  background: #162af1;
}
.chart-group-button .primary_btn:hover{
  box-shadow: none;
  color: #fff;
  border: 1px solid #162af1;
  background: #162af1;
}
.market_pair {
  display: flex;
  align-items: center;
  gap: 10px;
}
.market_pair img {
  width: 32px;
}
.market_pair p {
  margin-bottom: 0;
  color: #162af1;
}
.market_pair p span {
  color: #626262;
  font-size: 14px;
  margin-left: 2px;
}
.success_btn {
  padding: 6px 20px;
  font-size: 14px;
  text-transform: capitalize;
  color: #fff !important;
  border: 0;
  border-radius: 25px;
  background: #162af1;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  text-transform: capitalize;
  transition: all 0.3s ease 0s;
}